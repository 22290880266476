import s from "./gift-card-email-drawer.module.scss";

import _ from "lodash";
import { useTranslation } from "next-i18next";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/store";
import { cartSelector, setCart } from "@redux/slices/cartSlice";
import { Button, Form, Image, Input, Spin } from "antd";
import { DrawerCustom } from "../custom-drawer";

type GiftCardEmailDrawerProps = {
    isShow: boolean;
    handleCancel: () => void;
    handleOk: (value: string) => void;
    isMobile: boolean;
};

export const GiftCardEmailDrawer: FC<GiftCardEmailDrawerProps> = ({
    isShow,
    handleCancel,
    handleOk,
    isMobile,
}) => {
    const dispatch = useAppDispatch();
    const cart = useAppSelector(cartSelector);

    const [form] = Form.useForm();
    const { t } = useTranslation("common");
    const [loading, setLoading] = useState<boolean>(false);
    const [guestEmail, setGuestEmail] = useState<string>("");

    useEffect(() => {
        if (cart?.guestEmail) {
            setGuestEmail(cart.guestEmail);
            form.setFieldValue("email", cart.guestEmail);
        }
    }, [cart]);

    const handleSubmit = () => {
        setLoading(true);
        const _cart = _.cloneDeep({ ...cart, guestEmail });

        dispatch(setCart(_cart));
        sessionStorage.setItem("cart", JSON.stringify(_cart));

        handleOk(guestEmail);
        setLoading(false);
    };

    return (
        <DrawerCustom
            title={t("order.gift_card")}
            getContainer={false}
            open={isShow}
            onClose={handleCancel}
            footer={null}
            placement={isMobile ? "bottom" : "right"}
            className={`${!isMobile && s.giftCardEmailDesktopDrawer} ${
                s.giftCardEmailDrawer
            }`}
            closeIcon={<Image src="/icons/bt_close.svg" preview={false} />}
            key="gift-card"
            height={305}
        >
            <>
                <p className={`${s.textContent} mb-3`}>
                    {t("gift_card.email_content")}
                </p>

                <Form
                    form={form}
                    size="large"
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        name="email"
                        required
                        rules={[
                            {
                                required: true,
                                message: t("gift_card.email_required"),
                            },
                            {
                                type: "email",
                                message: t("gift_card.email_valid"),
                            },
                        ]}
                    >
                        <Input
                            value={guestEmail}
                            placeholder={t("gift_card.email_placeholder")}
                            className={`${s.inputSearch} w-full`}
                            onChange={(e) => setGuestEmail(e.target.value)}
                        />
                    </Form.Item>

                    <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        className="w-full h-12 mt-12"
                    >
                        {t("common.done")}
                    </Button>
                </Form>
            </>
        </DrawerCustom>
    );
};
