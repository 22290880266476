import { IS_SERVER } from "@utils/is-server";
import { useState } from "react";

const LOCATION_KEY = "location_key";

type Location = {
    city?: string;
    district?: string;
};

export const useLocation = (initialValue?: Location) => {
    const getValue = () => {
        if (IS_SERVER) return null;
        try {
            const storedValue = localStorage.getItem(LOCATION_KEY);
            return storedValue ? JSON.parse(storedValue) : initialValue;
        } catch (error) {
            console.error("Error getting region from localStorage:", error);
            return initialValue;
        }
    };

    const setValue = (value: Location) => {
        try {
            const serializedValue = JSON.stringify(value);
            localStorage.setItem(LOCATION_KEY, serializedValue);
            setStoredValue(value);
        } catch (error) {
            console.error("Error setting region to localStorage:", error);
        }
    };

    const [storedValue, setStoredValue] = useState(getValue());

    return [storedValue, setValue];
};
