"use client";

import { GiftCardDetailTypeEnum } from "@enums/gift-card-detail-type.enum";
import { OrderItemTypeEnum } from "@enums/order-item-type.enum";
import { OrderTypeEnum } from "@enums/order-type.enum";
import {
    CreditProviderEnum,
    PaymentMethodEnum,
} from "@enums/payment-method.enum";
import { ProductTypeEnum } from "@enums/product-type.enum";
import {
    EAddressType,
    EShippingAddressType,
    IOrderAddressDto,
    NCart,
    NCheckout,
    NOrderType,
    NProduct,
    NShippingSetting,
    NShop,
} from "@interfaces/components";
import { NPaymentSetting } from "@interfaces/components/payment-setting";
import {
    calcGiftCard,
    cartSelector,
    removeCart,
    setCart,
    setTip,
} from "@redux/slices/cartSlice";
import { guestSelector } from "@redux/slices/guestSlice";
import { settingSelector } from "@redux/slices/settingSlice";
import { shopSelector } from "@redux/slices/shopSlice";
import { tableSelector } from "@redux/slices/tableSlice";
import { useAppDispatch, useAppSelector } from "@redux/store";
import { OrderService, PaymentSettingService } from "@services/api";
import { useShippingSettings } from "@services/api-hook";
import { ProductInCartTemplate } from "@templates/product-in-cart";
import useCheckMobileScreen from "@utils/use-check-mobile-screen";
import { notification } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import { useTranslation } from "next-i18next";
import { useParams, useRouter } from "next/navigation";
import {
    Dispatch,
    FC,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useStore } from "react-redux";
import { FORMAT_DDDD, SHOP } from "../../../common/constant";
import { ShippingProviderEnum, UnitEnum } from "../../../enums";
import { CurrencyType } from "../../../enums/currency-type.enum";
import { OrderSourceEnum } from "../../../enums/order-source.enum";
import { useCurrentLocation } from "../../../hooks";
import { IBankAccount } from "../../../interfaces/market/bank.interface";
import { userSelector } from "../../../redux/slices";
import {
    MINIMUM_VND,
    addTime,
    formatDateTime,
    getCurrentDateTime,
    getNow,
    getSessionValue,
} from "../../../utils";
import { GiftCardChooseDrawer } from "../gift-card-choose-drawer";
import { GiftCardDetail } from "../gift-card-detail";
import { GiftCardEmailDrawer } from "../gift-card-email-drawer";
import { PaymentMethodList } from "../payment-method-list";
import { IPickUpTimeData } from "../pickup-time";

export enum PaymentMethodListVendor {
    Default = "Default",
    Fusoft = "Fusoft",
}

interface IPropsContext extends NCheckout.ICheckoutContext {
    setIsShowShippingAddressModal: Dispatch<SetStateAction<boolean>>;
    handleSelectOrderType: (method: OrderTypeEnum) => void;
    onPickUpTimeChange: (data: IPickUpTimeData) => void;
    isElementInBill: (element: NCart.IInCartDetail) => boolean;
    setIsOpenGcChoose: Dispatch<SetStateAction<boolean>>;
    handleSelectPaymentMethod?: (
        value: NPaymentSetting.IPaymentMethodResponse | null,
    ) => void;
    changeTip: (tip: number) => void;
    handleCheckEnterEmail: () => void;
    setCategoryId: Dispatch<SetStateAction<string | null>>;
    onEditItem: (data: NCart.ICartItem) => void;
    setConfirmDineIn: Dispatch<SetStateAction<NCheckout.ConfirmDineIn>>;
    PaymentMethodListCommon: ({
        className,
        paymentSettings,
        theme,
    }: {
        className: string;
        paymentSettings?: NPaymentSetting.IPaymentSettingDetail;
        theme?: string;
    }) => JSX.Element;
    setIsShowQRCheckout: (isShowQR: boolean) => void;
    setQRUrl: (url: string | null) => void;
    setShippingMethod: (
        method?:
            | {
                  provider: string;
                  shippingMethod?: string | undefined;
              }
            | undefined,
    ) => void;
}

const orderService = OrderService.getInstance();

const paymentSettingService = PaymentSettingService.getInstance();

export const CheckoutContext = createContext<IPropsContext | null>(null);

export const CheckoutContextProvider: FC = ({ children }) => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const { lng: language, store: storeCode } = useParams() || {};

    const { t } = useTranslation("common");
    const isMobile = useCheckMobileScreen();

    const shop = useAppSelector(shopSelector);
    const cart = useAppSelector(cartSelector);
    const guest = useAppSelector(guestSelector);
    const table = useAppSelector(tableSelector);
    const setting = useAppSelector(settingSelector);
    const user = useAppSelector(userSelector);

    const store = useStore();

    const { currencyConfig } = useAppSelector(settingSelector);

    const [productId, setProductId] = useState<string | null>(null);
    const [productType, setProductType] = useState<ProductTypeEnum>();
    const [categoryId, setCategoryId] = useState<string | null>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isShowQRCheckout, setIsShowQRCheckout] = useState<boolean>(false);
    const [qrUrl, setQRUrl] = useState<string | null>(null);

    const [cartItemId, setCartItemId] = useState<string | undefined>(undefined);
    const [isShowShippingAddressModal, setIsShowShippingAddressModal] =
        useState<boolean>(false);

    const [orderType, setOrderType] = useState<OrderTypeEnum | null | string>(
        null,
    );
    const [confirmDineIn, setConfirmDineIn] = useState<NCheckout.ConfirmDineIn>(
        {
            data: false,
            order: { incrementId: "" },
        },
    );
    const shopSession = getSessionValue<NShop.IPublicShop>(SHOP);

    const [loading, setLoading] = useState(false);
    const [isOpenGcChoose, setIsOpenGcChoose] = useState<boolean>(false);
    const [isOpenEnterEmail, setIsOpenEnterEmail] = useState<boolean>(false);
    const [loadingConfirmBtn, setLoadingConfirmBtn] = useState<boolean>(false);
    const [tipOption, setTipOption] = useState<number[]>([]);
    const [paymentSettings, setPaymentSettings] =
        useState<NPaymentSetting.IPaymentSettingDetail>();

    const [pickUpTime, setPickUpTime] = useState<IPickUpTimeData>({
        date: addTime(30, UnitEnum.minutes),
        isDelivery: true,
        isValid: true,
    });
    const [isUpdatingShippingMethod, setIsUpdatingShippingMethod] =
        useState(false);

    const [shippingMethods, setShippingMethods] = useState<
        NShippingSetting.ShippingMethod[]
    >([]);

    const { fetchShippingSettings, fetchEstimateShippingFee } =
        useShippingSettings(shop?.id);

    const {
        data: shippingSettings = undefined,
        isLoading: isLoadingShippingSettings,
        isValidating: isValidatingShippingSettings,
    } = fetchShippingSettings;

    const { getLocationApp } = useCurrentLocation();
    const { address } = useAppSelector(guestSelector);

    useEffect(() => {
        getLocationApp();
    }, []);

    const [shippingAddress, setShippingAddress] = useState<
        IOrderAddressDto | undefined
    >();

    const combineAddress = (address?: IOrderAddressDto) => {
        if (!address) return undefined;
        const { street, wardName, districtName, cityName } = address;
        return [street, wardName, districtName, cityName].join(", ");
    };

    const {
        data: estimateValues,
        isLoading: isLoadingEstimateShippingFee,
        isValidating: isValidatingEstimateShippingFee,
    } = fetchEstimateShippingFee({ address: combineAddress(shippingAddress) });

    useEffect(() => {
        if (!shop?.id || !estimateValues?.length || !shippingMethods?.length)
            return;

        const estimateHash = estimateValues.reduce((acc, e) => {
            acc[e.provider] = e;
            return acc;
        }, {} as { [key: string]: NShippingSetting.IGetEstimateShippingFeeResponseDto });

        const mapShippingFee = () => {
            shippingMethods.forEach((method) => {
                const estimate = estimateHash[method.provider];

                if (method.provider === ShippingProviderEnum.FlatRate) {
                    return;
                }

                if (!estimate) {
                    method.hidden = true;
                    return;
                }

                method.hidden = false;
                method.shippingFee = estimate.total_price;
                method.description = method.description;
                method.isActive = true;
            });

            const availableShippingMethods = shippingMethods.filter(
                (m) => m.isActive,
            );

            setShippingMethods([...availableShippingMethods]);
        };

        mapShippingFee();
    }, [
        shop?.id,
        JSON.stringify(estimateValues),
        JSON.stringify(shippingMethods),
    ]);

    const [bankAccount, setBankAccount] = useState<IBankAccount>();

    useEffect(() => {
        if (!shop?.id) {
            return;
        }

        const availableShippingMethods = shippingSettings?.methods.filter(
            (m) => m.isActive,
        );

        setShippingMethods(availableShippingMethods ?? []);
    }, [shippingSettings, shop?.id]);

    useEffect(() => {
        if (!shop?.id) {
            return;
        }
        if (cart.orderId && !cart.shippingMethod && shippingMethods?.length) {
            setShippingMethod({
                provider: shippingMethods[0].provider,
                shippingMethod: shippingMethods[0].methodKey,
            });
        }
    }, [cart.orderId, shippingMethods, shop?.id]);

    const setShippingMethod = async (
        method?:
            | {
                  provider: string;
                  shippingMethod?: string | undefined;
              }
            | undefined,
    ) => {
        if (
            !method ||
            !method.shippingMethod ||
            !cart.orderId ||
            isUpdatingShippingMethod
        )
            return;
        try {
            setIsUpdatingShippingMethod(true);

            const res = await orderService.updateShippingMethod({
                orderId: cart.orderId,
                shippingProvider: method.provider,
                shippingMethod: method.shippingMethod,
            });

            setIsUpdatingShippingMethod(false);
            updateCart(res);
        } catch (error) {
            setIsUpdatingShippingMethod(false);
        }
    };

    useEffect(() => {
        if (!shop) return;
        cart.shouldRefreshCart && updateCartItems();
    }, [JSON.stringify(cart.items), shop]);

    const fetchPaymentSetting = async () => {
        const paymentSettings =
            await paymentSettingService.getShopPaymentSettings(shop?.id);

        if (paymentSettings) {
            setPaymentSettings(paymentSettings);
            setTipOption(paymentSettings.tipOptions || []);
        } else {
            notification.error({
                className: "error-notification",
                message: "",
                description: (
                    <p className="pr-4">
                        {t("shop.no_available_payment_methods")}
                    </p>
                ),
            });
        }
    };

    useEffect(() => {
        // Handle guess
        if (user?.id || !cart?.orderId) return;

        if (guest?.address && cart?.orderId) {
            const param: NOrderType.IUpdateInformationOrder = {
                orderId: cart.orderId,
                customerId: guest?.id || "",
                customerPhone:
                    guest.address?.phoneNumber ?? guest.phoneNumber ?? "",
                customerName: guest.address?.name ?? guest.address?.fullName,
                shippingAddress: guest.address,
            };
            setLoading(true);
            updateInformationOrder(param)
                .then((orderRes) => {
                    dispatch(
                        setCart({
                            ...cart,
                            estimateShippingFee: orderRes?.estimateShippingFee,
                            grandTotal: orderRes?.grandTotal,
                        }),
                    );
                    guest.address && setShippingAddress({ ...guest.address });
                })
                .finally(() => setLoading(false));
        }
    }, [cart?.orderId, guest?.customerName, JSON.stringify(guest?.address)]);

    useEffect(() => {
        // Handle user
        if (cart?.orderId && user?.id) {
            const orderAddress = user.selectedAddress;

            if (!orderAddress) return;

            const address = {
                ...orderAddress,
                ...{
                    name: orderAddress.fullName,
                    type: EAddressType.ShippingAddress,
                    cityName: orderAddress.city,
                    districtName: orderAddress.district,
                    wardName: orderAddress.ward,
                    street: orderAddress.streetAddress,
                    shippingAddressType:
                        orderAddress.type ?? EShippingAddressType.HOME,
                },
            } as unknown as IOrderAddressDto;

            const param: NOrderType.IUpdateInformationOrder = {
                orderId: cart.orderId,
                customerId: user?.id || "",
                customerPhone: user.phoneNumber || "",
                customerName: user.name,
                shippingAddress: address,
            };
            setLoading(true);
            updateInformationOrder(param)
                .then((orderRes) => {
                    if (orderRes) {
                        dispatch(
                            setCart({
                                ...cart,
                                estimateShippingFee:
                                    orderRes?.estimateShippingFee,
                                grandTotal: orderRes?.grandTotal,
                            }),
                        );
                    }
                    setShippingAddress(address);
                })
                .finally(() => setLoading(false));
        }
    }, [user?.selectedAddress, cart?.orderId]);

    const updateInformationOrder = async (
        param: NOrderType.IUpdateInformationOrder,
    ): Promise<NOrderType.IOrderResponse | undefined> => {
        try {
            return await orderService.updateInformation(param);
        } catch (error) {
            notification.error({
                message: "",
                description: t("shipping_address.invalid_user_address"),
            });
        }
    };

    useEffect(() => {
        setLoading(true);

        if (!orderType) {
            setLoading(false);
            return;
        }

        let pickUpTime: IPickUpTimeData = {
            date: null,
            isDelivery: false,
            isValid: true,
        };

        if (orderType !== OrderTypeEnum.DINEIN) {
            pickUpTime = {
                date: addTime(30, UnitEnum.minutes),
                isDelivery: false,
                isValid: true,
            };
        }
        setLoading(false);
    }, [orderType]);

    useEffect(() => {
        if (shop?.id) {
            fetchPaymentSetting();
            fetchData(OrderTypeEnum.DELIVERY);
        }
    }, [shop?.id]);

    const handleSelectOrderType = (method: OrderTypeEnum) => {
        setOrderType(method);
        dispatch(setTip(0));
        fetchData(method);
    };

    const refreshCart = (result: NOrderType.IOrderResponse) => {
        const _cart = _.cloneDeep(cart);

        _cart.orderId = result.id;
        _cart.subTotal = result.subTotal;
        _cart.grandTotal = result.grandTotal;
        _cart.taxAmount = result.taxAmount;
        _cart.extraFee = result.extraFee;
        _cart.discountAmount = result.discountAmount;
        _cart.discountIds = result.discountIds;
        _cart.orderItemType = result.orderItemType || OrderItemTypeEnum.PRODUCT;
        _cart.estimateShippingFee = result.estimateShippingFee;
        _cart.shippingFee = result.shippingFee;
        _cart.shippingMethod = result.shippingMethod;

        _cart.items.forEach((item) => {
            const _item = result?.orderItems?.find(
                (orderItem: { id: string }) => orderItem.id === item.id,
            );

            if (!_item) {
                item.deletedAt = dayjs().toString();
            } else {
                item.price = _item.price;
                item.qty = _item.qty;
                if (shop.isPriceIncludedTax) {
                    item.total = _item.total;
                } else {
                    item.total = _item.price * _item.qty;
                    if (_item.linkedProductGroups?.length) {
                        _item.linkedProductGroups.forEach((lkpg) => {
                            lkpg.linkedProducts.forEach((lkp) => {
                                item.total += lkp.price * _item.qty;
                            });
                        });
                    }
                }
                item.productType = _item.productType;

                item.deletedAt = undefined;
            }
        });

        _cart.isEmptyCart = result?.orderItems?.length < 1;

        if (_cart.isEmptyCart) {
            dispatch(removeCart());
        } else {
            dispatch(setCart(_cart));
            sessionStorage.setItem("cart", JSON.stringify(_cart));
        }
    };

    const fetchData = async (type: OrderTypeEnum | string) => {
        if (cart.orderId) {
            setLoading(true);

            const result = await orderService.selectType({
                orderId: cart?.orderId || "",
                type,
                orderItemType: cart.orderItemType,
            });

            if (!result) {
                setLoading(false);
                return;
            }

            refreshCart(result);

            // Calc gift card
            if (cart.giftCardAmount && cart.giftCardGroups?.length) {
                dispatch(
                    calcGiftCard({
                        orderId: result?.id || "",
                        giftCardGroups: cart.giftCardGroups,
                        giftCardAmount: cart.giftCardAmount,
                    }),
                );
            }

            setLoading(false);
        }
    };

    const handleCheckoutSubmit = async (guestEmail?: string) => {
        if (
            currencyConfig?.code === CurrencyType.Vnd &&
            cart?.grandTotal &&
            cart?.grandTotal / 100 < MINIMUM_VND
        ) {
            orderService.createErrorNotification(t("order.minimum_vnd"));
            setLoading(false);
            return;
        }

        const { paymentReducer: payment } = store.getState() as {
            paymentReducer: any;
        };

        setLoading(true);

        const request: NCheckout.IPlaceOrderRequest = {
            orderId: cart?.orderId || "",
            tipAmount: cart?.tipAmount || 0,
            deliveryTime: pickUpTime?.date
                ? dayjs(pickUpTime?.date).toDate()
                : null,
            isDelivery: pickUpTime?.isDelivery,
            guestEmail: guestEmail || "",
            giftCardAmount:
                orderType === OrderTypeEnum.TOGO ? cart?.giftCardAmount : 0,
            giftCardGroups:
                orderType === OrderTypeEnum.TOGO ? cart?.giftCardGroups : [],
            note: cart.note,
            ts: guest.ts,
            shopId: shop.id,
            source: OrderSourceEnum.MARKETPLACE,
        };

        if (
            [PaymentMethodEnum.CASH, PaymentMethodEnum.DEDUCT].includes(
                payment?.paymentMethod as PaymentMethodEnum,
            )
        ) {
            request.paymentMethod = payment?.paymentMethod;
            request.paymentProvider = undefined;
        } else {
            request.paymentMethod = PaymentMethodEnum.CREDIT;
            request.paymentProvider = {
                method: payment?.paymentProvider,
                service: payment?.serviceProvider,
                provider: payment?.provider,
            };

            switch (payment.paymentProvider) {
                case CreditProviderEnum.ONEPAY:
                case CreditProviderEnum.BAOKIM: {
                    request.paymentProvider.redirectUrl = `${window.location?.origin}/checkout/result`;
                    break;
                }
            }
        }

        setLoadingConfirmBtn(true);
        if (
            orderType === OrderTypeEnum.DINEIN &&
            !table.id &&
            cart.orderItemType !== OrderItemTypeEnum.GIFT_CARD
        ) {
            setConfirmDineIn({ data: false, order: { incrementId: "" } });
            orderService.createErrorNotification(
                t("order.order_type_incorrect"),
            );

            setLoading(false);

            return;
        }

        const result = await orderService.placeOrder(request);

        if (result?.data?.result) {
            if (result.data.orderId && cart.orderId !== result.data.orderId) {
                const _cart = _.cloneDeep(cart);
                _cart.orderId = result.data.orderId;
                const newItems = (_cart.items || []).filter((item) => {
                    if (result.data?.mappingCartItemIds?.[item.id]) {
                        item.id = result.data?.mappingCartItemIds?.[item.id];
                        return true;
                    }
                });

                _cart.items = newItems;
                dispatch(setCart(_cart));
            }
            const grandTotal = cart.grandTotal ?? 0;
            const tip = cart.tipAmount ?? 0;

            const giftCardAmount =
                orderType === OrderTypeEnum.TOGO ? cart.giftCardAmount : 0;

            const total = (
                Math.round(
                    ((grandTotal + tip - giftCardAmount) / 100 +
                        Number.EPSILON) *
                        100,
                ) / 100
            ).toFixed(2);

            if (result?.data?.clientSecret) {
                const bankAccount: IBankAccount = {
                    bankName: result?.data?.accName || "",
                    accountNo: result?.data?.accNo || "",
                    bankShortName: result?.data?.bankShortName || "",
                    paymentAmount: result?.data?.paymentAmount
                        ? result?.data?.paymentAmount * 100
                        : cart.grandTotal,
                };
                setBankAccount(bankAccount);

                switch (payment.paymentProvider) {
                    case CreditProviderEnum.BAOKIM: {
                        setQRUrl(result.data.clientSecret);
                        setIsShowQRCheckout(true);
                        setLoadingConfirmBtn(false);
                        setLoading(false);
                        break;
                    }
                    default: {
                        window.location.replace(result.data.clientSecret);
                    }
                }
            } else {
                if (+total === 0 || result?.data.incrementId) {
                    const lang = language ?? "vi";
                    if (result?.data.orderId && storeCode) {
                        router.push(
                            `/${lang}/${storeCode}/checkout/complete?orderId=${result?.data?.orderId}`,
                        );
                    } else {
                        router.push(`/${lang}`);
                    }
                    dispatch(removeCart());
                } else {
                    orderService.createErrorNotification(
                        t("order.pay_order_failed"),
                    );

                    router.push(`/order/${result?.data.orderId}`);
                }

                setLoading(false);
            }
        } else if (result?.data?.error) {
            switch (result.data.error) {
                default:
                    orderService.createErrorNotification(
                        t("order.error_occurred"),
                    );
            }
            setLoadingConfirmBtn(false);
            setLoading(false);
        } else {
            let time = "";
            if (result?.err?.data) {
                const { fromHour, toHour } = result.err.data;
                const from = getCurrentDateTime(fromHour);
                const to = getCurrentDateTime(toHour);
                if (from.dayName === to.dayName)
                    time = `from ${from.hour} to ${to.hour} on ${from.dayName}`;
                else
                    time = `from ${from.hour} - ${from.dayName} to ${to.hour} - ${to.dayName}`;
            }

            const message = t(
                result?.err?.error
                    ? `shop.${result?.err?.error}`
                    : result?.err
                    ? `order.${result.err}`
                    : "order.error_occurred",
                {
                    date: pickUpTime.date
                        ? formatDateTime(pickUpTime?.date, FORMAT_DDDD)
                        : getNow(FORMAT_DDDD),
                    time: time,
                },
            );

            orderService.createErrorNotification(message);

            setLoadingConfirmBtn(false);
            setLoading(false);
        }
    };

    const updateCartItems = async () => {
        const selectedAddress = user?.selectedAddress;
        const defaultAddress = user?.addresses?.find((a) => a.isDefault);

        const userAddress = selectedAddress ?? defaultAddress;

        let address: IOrderAddressDto | undefined;
        if (userAddress) {
            address = {
                fullName: userAddress.fullName,
                phoneNumber: userAddress.phoneNumber,
                street: userAddress.streetAddress,
                type: EAddressType.ShippingAddress,
                cityName: userAddress.city,
                cityCode: userAddress.cityCode,
                cityId: userAddress.cityCode,
                districtName: userAddress.district,
                districtCode: userAddress.districtCode,
                wardName: userAddress.ward,
                wardCode: userAddress.wardCode,
            } as IOrderAddressDto;
        } else if (guest?.address) {
            address = { ...guest.address };
        }

        const customerName =
            user?.name || guest?.customerName || address?.fullName;
        const customerPhoneNumber =
            user?.phoneNumber || guest?.phoneNumber || address?.phoneNumber;
        setLoading(true);

        const _cart = _.cloneDeep(cart);

        const request: NOrderType.ICreateOrder = {
            note: cart?.note,
            tableId: table?.id,
            tableName: table?.name,
            shopId: shop?.id || shopSession?.id,
            customerName: customerName,
            customerPhone: customerPhoneNumber,
            customerId: user?.id ?? guest?.id,
            orderItems: [],
            pax: guest?.numberOfPeople ? +guest?.numberOfPeople : 1,
            orderItemType: cart.orderItemType,
            externalData: guest?.ipPc
                ? {
                      userId: guest?.userId,
                      data: guest?.data,
                      ipPC: guest?.ipPc,
                      pcName: guest?.pcName,
                      ts: guest?.ts,
                      host: guest?.host,
                      tenantId: guest?.tenantId,
                      username: guest?.username,
                      shopId: guest?.shopId,
                      fuid: guest?.fuid,
                  }
                : undefined,
            shippingAddress: address,
        };

        cart.items.forEach((item) => {
            request.orderItems.push({
                id: item.id,
                productId: item.productId,
                productSizeId: item?.productSizeId,
                discountIds: item?.discount?.id ? [item?.discount?.id] : [],
                qty: item?.qty || 1,
                note: item?.note,
                optionTypes: item?.selectOptionTypes?.length
                    ? item?.selectOptionTypes.map((selectOptionType) => {
                          return {
                              id: selectOptionType.optionTypeId,
                              name: selectOptionType.name,
                              options: selectOptionType.options,
                              isMultiChoice: selectOptionType.isMultiChoice,
                          };
                      })
                    : [],
                linkedProductGroups: item?.selectToppingGroups?.length
                    ? item?.selectToppingGroups?.map((tg) => {
                          return {
                              id: tg.id,
                              name: tg.name,
                              maxTopping: tg.maxTopping,
                              linkedProducts: getLinkedProducts(
                                  tg.linkedProducts,
                              ),
                          };
                      })
                    : [],
                productType: item.productType,
            });
        });

        let result: Partial<NOrderType.IOrderResponse> = {};

        if (cart.orderId) {
            let updateRequest: NOrderType.IUpdateOrder = {
                orderId: cart.orderId,
                orderItems: request.orderItems,
                shopId: shop?.id || shopSession?.id,
                orderItemType: cart.orderItemType,
            };
            if (table?.id) {
                updateRequest = {
                    ...updateRequest,
                    orderType,
                    tableId: table?.id,
                    tableName: table?.name || "",
                    pax: table?.pax ?? 1,
                };
            }

            result = await orderService.updateOrderItem(updateRequest);
        } else {
            if (!table?.id && !address) {
                const orderDefault = shop?.setting?.orderType?.toGo;
                if (orderDefault) {
                    request.toGo = true;
                } else {
                    request.toGo = false;
                }
            } else if (table?.id) {
                request.pax = table?.pax ?? 1;
                const orderDefault = shop?.setting?.orderType?.orderDefault;
                if (orderDefault) {
                    request.toGo =
                        orderDefault === OrderTypeEnum.TOGO ? true : false;
                }
            }

            if (!request.toGo && address) {
                setOrderType(OrderTypeEnum.DELIVERY);
                request.toGo = false;
            } else {
                setOrderType(
                    request.toGo ? OrderTypeEnum.TOGO : OrderTypeEnum.DINEIN,
                );
            }

            result = await orderService.createOrder(request);
        }

        if (result?.error === "invalid_time_to_order") {
            setLoading(false);
            orderService.createErrorNotification(t("invalid_time_to_order"));
            return;
        }

        if (!result) {
            setLoading(false);
            orderService.createErrorNotification(t("order.error_occurred"));
            return;
        }

        updateCart(result);

        // Calc gift card
        if (cart.giftCardAmount && cart.giftCardGroups?.length) {
            dispatch(
                calcGiftCard({
                    orderId: result?.id || "",
                    giftCardGroups: _cart.giftCardGroups,
                    giftCardAmount: _cart.giftCardAmount,
                }),
            );
        }

        setLoading(false);
    };

    const updateCart = (dto: Partial<NOrderType.IOrderResponse>) => {
        const _cart = _.cloneDeep(cart);

        _cart.orderId = dto.id;
        _cart.subTotal = dto.subTotal;
        _cart.grandTotal = dto.grandTotal;
        _cart.taxAmount = dto.taxAmount;
        _cart.extraFee = dto.extraFee;
        _cart.discountAmount = dto.discountAmount;
        _cart.orderItemType = dto.orderItemType || OrderItemTypeEnum.PRODUCT;
        _cart.estimateShippingFee = dto.estimateShippingFee;
        _cart.shippingMethod = dto.shippingMethod;

        _cart.items.forEach((item) => {
            const _item = dto?.orderItems?.find(
                (orderItem: { id: string }) => orderItem.id === item.id,
            );

            if (!_item) {
                item.deletedAt = dayjs().toString();
            } else {
                item.price = _item.price;
                item.qty = _item.qty;
                if (shop.isPriceIncludedTax) {
                    item.total = _item.total;
                } else {
                    item.total = _item.price * _item.qty;
                    if (_item.linkedProductGroups?.length) {
                        _item.linkedProductGroups.forEach((lkpg) => {
                            lkpg.linkedProducts.forEach((lkp) => {
                                item.total += lkp.price * _item.qty;
                            });
                        });
                    }
                }
                item.productType = _item.productType;

                item.deletedAt = undefined;
            }
        });

        _cart.isEmptyCart = (dto?.orderItems || [])?.length < 1;

        if (_cart.isEmptyCart) {
            dispatch(removeCart());
        } else {
            _cart.shouldRefreshCart = false;
            dispatch(setCart(_cart));
            sessionStorage.setItem("cart", JSON.stringify(_cart));
        }
    };

    const getLinkedProducts = (linkedProducts: NProduct.ILinkedProduct[]) => {
        const result: any[] = [];

        linkedProducts.forEach((lp) => {
            if (!lp.qty) {
                return;
            }

            result.push({
                id: lp.id,
                productId: lp.productId,
                productSizeId: lp.productSizeId,
                linkedProductId: lp.linkedProductId,
                linkedProductSizeId: lp.linkedProductSizeId,
                qty: 1,
                discountId: lp?.discount?.id,
            });
        });
        return result;
    };

    const isElementInBill = (element: NCart.IInCartDetail): boolean => {
        if (element.key === "estimateShippingFee") return true;
        if (
            orderType === OrderTypeEnum.DINEIN &&
            element.key === "giftCardAmount"
        ) {
            return false;
        }

        if (!element.isDependent) return true;

        const value = Number(cart[element.key]) || 0;

        if (!value) return false;

        return value > 0;
    };

    const changeTip = (tip: number) => {
        const _cart = { ...cart };

        _cart.tipAmount = tip;
        dispatch(setCart(_cart));
    };

    const onPickUpTimeChange = (data: IPickUpTimeData) => {
        setPickUpTime(data);
    };

    const handleCheckEnterEmail = () => {
        setLoading(true);

        const orderItems = cart.items;
        const isOpen = orderItems.some(
            (orderItem) => orderItem.productType === ProductTypeEnum.GIFT_CARD,
        );

        if (isOpen && !guest?.id) {
            setIsOpenEnterEmail(isOpen);
        } else {
            handleCheckoutSubmit();
        }
    };

    const total = useMemo(() => {
        if (!cart) {
            return 0;
        }

        if (cart.orderItemType === OrderItemTypeEnum.GIFT_CARD) {
            setOrderType(OrderTypeEnum.TOGO);
        }

        let _total =
            (cart?.extraFee || 0) +
            (cart?.subTotal || 0) +
            (cart?.taxAmount || 0) +
            (cart?.tipAmount || 0) -
            (cart?.discountAmount || 0) +
            (cart?.estimateShippingFee || 0);

        if (orderType === OrderTypeEnum.TOGO) {
            _total -= cart?.giftCardAmount || 0;
        }

        return _total;
    }, [cart]);

    const onEditItem = (data: NCart.ICartItem) => {
        setIsEdit(true);
        setCategoryId(data?.categoryId || null);
        setProductId(data.productId);
        setCartItemId(data.id);
        setProductType(data.productType);
    };

    const handleSelectPaymentMethod = (
        value: NPaymentSetting.IPaymentMethodResponse | null,
    ) => {
        if (!value) {
            return;
        }

        [(PaymentMethodEnum.CASH, PaymentMethodEnum.DEDUCT)].includes(
            value.paymentMethod as PaymentMethodEnum,
        ) && setTip(0);
    };

    const PaymentMethodListCommon = ({
        className,
        theme,
        paymentSettings,
    }: {
        className: string;
        theme?: string;
        paymentSettings?: NPaymentSetting.IPaymentSettingDetail;
    }) => {
        let _orderType = orderType;
        return (
            <div className={`${className} ${theme}`}>
                <PaymentMethodList
                    paymentSettings={paymentSettings}
                    orderType={_orderType as OrderTypeEnum}
                    onSelect={handleSelectPaymentMethod}
                />
            </div>
        );
    };

    const creditConfig = (paymentSettings?.methods || []).find(
        (method) => method.type === PaymentMethodEnum.CREDIT,
    );
    const isEnabledCreditMethod = creditConfig?.isActive;
    const isEnoughCreditConfig = creditConfig?.isConfigured;

    const value: IPropsContext = {
        shop,
        cart,
        guest,
        user,
        table,
        setting,
        currencyConfig,
        loading:
            loading ||
            isLoadingEstimateShippingFee ||
            isLoadingShippingSettings ||
            isUpdatingShippingMethod,
        orderType,
        pickUpTime,
        isEnabledCreditMethod,
        isEnoughCreditConfig,
        productType,
        categoryId,
        isShowShippingAddressModal,
        loadingConfirmBtn,
        total,
        setIsShowShippingAddressModal,
        handleSelectOrderType,
        onPickUpTimeChange,
        isElementInBill,
        setIsOpenGcChoose,
        handleSelectPaymentMethod,
        changeTip,
        handleCheckEnterEmail,
        setCategoryId,
        onEditItem,
        confirmDineIn,
        tipOption: tipOption,
        setConfirmDineIn,
        paymentSettings,
        PaymentMethodListCommon,
        setIsShowQRCheckout,
        setQRUrl,
        isShowQRCheckout,
        qrUrl,
        shippingMethods,
        bankAccount,
        setShippingMethod,
    };

    return (
        <CheckoutContext.Provider value={value}>
            {children}
            {productId &&
                (productType === ProductTypeEnum.GIFT_CARD ? (
                    <GiftCardDetail
                        type={GiftCardDetailTypeEnum.SALE}
                        giftCardId={productId}
                        isOpen={true}
                        onClose={() => {
                            setProductId(null);
                        }}
                        cartItemId={cartItemId}
                        isMobile={isMobile}
                    />
                ) : (
                    <ProductInCartTemplate
                        productId={productId}
                        onClose={() => {
                            setProductId(null);
                            setCategoryId(null);
                            setIsEdit(false);
                        }}
                        cartItemId={cartItemId}
                        categoryId={categoryId || ""}
                        isEdit={isEdit}
                    />
                ))}

            <GiftCardEmailDrawer
                isShow={isOpenEnterEmail}
                handleCancel={() => setIsOpenEnterEmail(false)}
                handleOk={(value: string) => {
                    handleCheckoutSubmit(value);
                }}
                isMobile={isMobile}
            />

            <GiftCardChooseDrawer
                isOrderDetail={false}
                isShow={isOpenGcChoose}
                isCustomer={!!(guest?.id || user?.id)}
                handleCancel={() => {
                    setIsOpenGcChoose(false);
                }}
                isMobile={isMobile}
            />
        </CheckoutContext.Provider>
    );
};

export const useCheckoutContext = (): IPropsContext => {
    const context = useContext(CheckoutContext);
    if (!context) {
        throw new Error("context is null");
    }
    return context;
};
