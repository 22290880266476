import { setGuestLatLng } from "@redux/slices";
import { useAppDispatch } from "@redux/store";
import { notification } from "antd";
import { useTranslation } from "next-i18next";

type LatLng = {
    lat: number;
    lng: number;
    timestamp?: number;
};

const GET_LOCATION_KEY = "sm/get_user_location";

export const useCurrentLocation = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation("common");

    const getLocationApp = () => {
        (window as any).flutter_inappwebview
            ?.callHandler(GET_LOCATION_KEY)
            .then((result: any) => {
                if (!result) {
                    notification.error({
                        className: "error-notification",
                        message: "",
                        description: (
                            <p className="pr-4">
                                {t("common.get_current_address_error")}
                            </p>
                        ),
                    });
                }
                dispatch(
                    setGuestLatLng({
                        ...(result?.data as LatLng),
                    }),
                );
            })
            .catch(() =>
                notification.error({
                    className: "error-notification",
                    message: "",
                    description: (
                        <p className="pr-4">
                            {t("common.get_current_address_error")}
                        </p>
                    ),
                }),
            );
    };

    return { getLocationApp };
};
